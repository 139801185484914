import React from "react";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";

const References = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "reference" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              slug
              title
              date
              content
              images {
                image {
                  childImageSharp {
                    gatsbyImageData(width: 200)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  console.log(allMarkdownRemark);
  return (
    <div className="References">
      <h1 className="References__title">Reference z našich staveb</h1>
      {allMarkdownRemark.edges.map(({ node }) => (
        <Link
          key={node.id}
          className="References__item"
          to={`/reference/${node.frontmatter.slug}`}
        >
          <div className="References__wrapper">
            <h2 className="References__name">{node.frontmatter.title}</h2>
            <div
              className="References__excerpt"
              dangerouslySetInnerHTML={{ __html: node.frontmatter.content }}
            />
          </div>
          {node.frontmatter.images && (
            <Img
              className="References__img"
              image={getImage(node.frontmatter.images[0].image)}
            />
          )}
        </Link>
      ))}
    </div>
  );
};

export default References;
