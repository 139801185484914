import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import References from "../components/references";

const Reference = () => {
  return (
    <Layout path="reference">
      <SEO title="Reference" />
      <References />
    </Layout>
  );
};

export default Reference;
